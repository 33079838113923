import { Grid } from "@material-ui/core";
import React from "react";

const StaticTextRow = ({ element }) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <label>{element.text}</label>
      </Grid>
    </Grid>
  );
};

export default StaticTextRow;
