import FieldDescription from "./fieldDescription";
import { Grid } from "@material-ui/core";
import React from "react";
const ActionDropdownRow = ({
  element,
  values,
  elementIds,
  setElementIds,
  handleChange,
  disableForm,
  getDropdownValues,
  nameError,
}) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <label>{element.text}</label>
      </Grid>

      <Grid item xs={6} style={{ textAlign: "right" }}>
        <FieldDescription description={element?.fieldDescription} />
        <select
          name={element.name}
          id={values.dropdown}
          className={`form-control`}
          onChange={(e) => {
            const currentId = element.elementId;
            if (!e.target.value && elementIds?.includes(currentId)) {
              // remove element if it exists
              setElementIds(elementIds.filter((id) => id !== currentId));
            }
            handleChange(e, "dropdown", element.elementId);
            // check if currentId is not present in elementId array
            if (!elementIds?.includes(currentId)) {
              setElementIds((prevState) => [...prevState, currentId]);
            }
          }}
          disabled={disableForm && true}
        >
          {getDropdownValues(element)}
        </select>
        {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
    </Grid>
  );
};

export default ActionDropdownRow;
