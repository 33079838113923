import "react-table/react-table.css";
import "./App.scss";
import "./Table.scss";
import "./dropdown.css";
import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { MainProvider } from "./context";
import React from "react";
import ReactDOM from "react-dom";
import de_data from "./languageFile/german.json";
import en_data from "./languageFile/english.json";
import history from "./shared/history";
import i18n from "./i18n";
import ru_data from "./languageFile/russian.json";

const messages = {
  en: en_data,
  de: de_data,
  ru: ru_data,
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter history={history}>
      <MainProvider>
        <App messages={messages} />
      </MainProvider>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);
