import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const DropdownRow = ({
  element,
  open,
  values,
  elementIds,
  setElementIds,
  handleChange,
  disableForm,
  getDropdownValues,
  nameError,
}) => {
  const isOpen = open && element?.description;
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={3}>
        <label>{element.text}</label>
      </Grid>
      <Grid item xs={isOpen ? 7 : 9} style={{ textAlign: "right" }}>
      <FieldDescription description={element?.fieldDescription} />

        <Box className="d-flex align-items-center">
          <select
            name={element.name}
            id={values.dropdown}
            className={`form-control`}
            onChange={(e) => {
              const currentId = element.elementId;
              if (!e.target.value && elementIds?.includes(currentId)) {
                // remove element if it exists
                setElementIds(elementIds.filter((id) => id !== currentId));
              }
              handleChange(e, "dropdown", element.elementId);
              // check if currentId is not present in elementId array
              if (!elementIds?.includes(currentId)) {
                setElementIds((prevState) => [...prevState, currentId]);
              }
            }}
            disabled={disableForm && true}
          >
            {getDropdownValues(element)}
          </select>
          {isOpen ? "" : element?.description && <div className="pl-1 mb-2">...</div>}
        </Box>
        {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(element?.description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DropdownRow;
