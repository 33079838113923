import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const DateRow = ({
  element,
  handleChange,
  disableForm,
  dobError,
  nameError,
  open,
}) => {
  const isOpen = open && element?.description;

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={3} className="text-left">
        <label>{element.text}</label>
      </Grid>
      <Grid item xs={isOpen ? 7 : 9} className="text-right">
      <FieldDescription description={element?.fieldDescription} />

        <Box className="d-flex align-items-center">
          <input
            type="text"
            name={element.name}
            id="DOB"
            readOnly={element.readOnly}
            onChange={(e) => {
              handleChange(e, "", element.elementId);
            }}
            autoComplete={"off"}
            placeholder="Please enter date"
            defaultValue={element.valueDB}
            className={`form-control phone_us ${dobError && "invalid"}`}
            disabled={disableForm && true}
          />
          {isOpen ? "" : element?.description && <div className="pl-1 mb-2">...</div>}
        </Box>
        {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(element?.description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DateRow;
