import { Grid } from "@material-ui/core";
import React from "react";

const HtmlElementRow = ({ element }) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <div
          dangerouslySetInnerHTML={{
            __html: element.targetValue.split(".").slice(-1)[0] || "",
          }}
        ></div>
      </Grid>
      <Grid item xs={7} style={{ textAlign: "right" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: element.valueDB || "",
          }}
        ></div>
      </Grid>
    </Grid>
  );
};

export default HtmlElementRow;
