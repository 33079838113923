import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.js";
import ru from "./translations/ru.js";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: en,
    ru: ru,
  },
  lng: "ru",
  fallbackLng: "ru",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
