import React from 'react';
import parse from 'html-react-parser';
const FieldDescription = ({description}) => {
  if (!description) return null
  return (
        <div style={{
          fontSize: '0.8rem',
          color: '#909090 !important',
          textAlign: 'right',
          marginBottom: '0.5rem',
          marginRight: '1.2rem',
        }} id="description-text">
          {parse(description)}
        </div>
  );
};

export default FieldDescription;