import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const EmailRow = ({
  text,
  name,
  valueDB,
  readOnly,
  emailError,
  handleChange,
  elementId,
  disableForm,
  description,
  nameError,
  open,
  fieldLength,
  fieldDescription,
}) => {
  const isOpen = open && description;
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={3}>
        <label>{text}</label>
      </Grid>

      <Grid item xs={isOpen? 7 : 9}>
      <FieldDescription description={fieldDescription} />

        <Box className="d-flex align-items-center">
          <input
            type="text"
            name={name}
            defaultValue={valueDB}
            readOnly={readOnly}
            autoComplete={"off"}
            {
              ...fieldLength && {
                maxLength: fieldLength,
              }
            }
            className={`form-control ${emailError && "invalid"}`}
            placeholder="Email address"
            onChange={(e) => handleChange(e, "", elementId)}
            disabled={disableForm && true}
          />
          {isOpen ? "" : description && <div className="pl-1 mb-2">...</div>}
        </Box>
          {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EmailRow;
