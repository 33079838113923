import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const TextRow = ({
  element,
  handleChange,
  mobileError,
  disableForm,
  nameError,
  open,
}) => {
  const isOpen = open && element?.description;
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={3}>
        <label>{element.text}</label>
      </Grid>
      <Grid item xs={isOpen ? 7 : 9}>
      <FieldDescription description={element?.fieldDescription} />

        <Box className="d-flex align-items-center">
          <input
            type="text"
            name={element.name}
            id={element.elementId}
            readOnly={element.readOnly}
            defaultValue={element.valueDB}
            autoComplete={"off"}
            {
              ...element?.fieldLength && {
                minLength: element.fieldLength,
              }
            }
            {
              ...element?.fieldLengthMax && {
                maxLength: element.fieldLengthMax,
              }
            }
            className={`form-control phone_us ${mobileError && "invalid"}`}
            onChange={(e) => handleChange(e, "", element.elementId)}
            disabled={disableForm && true}
          />
          {isOpen ? "" : element?.description && <div className="pl-1 mb-2">...</div>}
        </Box>
        {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(element?.description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TextRow;
