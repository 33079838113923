import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const CheckboxRow = ({ element, getCheckboxValues, nameError, open }) => {
  const isOpen = open && element?.description;
  return (
    <Grid
      container
      justifyContent={"space-between"}
    >
      <Grid item >
        <label>{element.text}</label>
      </Grid>
      <Grid item xs={isOpen ? 7 : 9} style={{ textAlign: "right" }}>
      <FieldDescription description={element?.fieldDescription} />

        <Box className="d-flex align-items-baseline justify-content-end">
          {getCheckboxValues(element)}
          {isOpen ? "" : element?.description && <div className="pl-1 mb-2">...</div>}
        </Box>
        {nameError && <span style={{ color: "red" }}>{nameError}</span>}
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(element?.description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CheckboxRow;
