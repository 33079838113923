import Fade from 'react-bootstrap/Fade';
import React from 'react';

const ToggleDescription = ({description, open}) => {
    return (
      <>
       {open && <Fade in={open}>
          <div style={{
            fontSize: '0.7rem',
            color: '#909090 !important',
          }} id="description-text">
           {description}
          </div>
        </Fade>}
      </>)
};

export default ToggleDescription;