import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import {
  COMPLAINT_SECTION,
  HTML_ELEMENT,
  HTML_SECTION,
  REDIAL_BUTTON,
  REDIAL_SECTION,
  SAVE_BUTTON_LINE,
  SAVE_BUTTON_SECTION,
  STATIC_TEXT,
  TWO_COLUMN_ACTION_DROPDOWN,
  TWO_COLUMN_CHECKBOX_ROW,
  TWO_COLUMN_DATE_ROW,
  TWO_COLUMN_DROPDOWN_ROW,
  TWO_COLUMN_EMAIL_ROW,
  TWO_COLUMN_HYPERLINK,
  TWO_COLUMN_NUMBER_ROW,
  TWO_COLUMN_PHONE_ROW,
  TWO_COLUMN_RADIO_BUTTON_ROW,
  TWO_COLUMN_SECTION,
  TWO_COLUMN_TEXT_ROW,
  WRONG_NUMBER_BUTTON,
} from "./constant";
import { Grid, Typography } from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCallTask, getComposedForm } from "./services/sidebarApi";
import { permissionErrorCheck, renderFormatMsg } from "./shared/funs";

import ActionDropdownRow from "./components/actionDropdownRow";
import { AppContext } from "./context/AppContext";
import Axios from "axios";
import { Button } from "react-bootstrap";
import CheckboxRow from "./components/checkboxRow";
import DateRow from "./components/dateRow";
import DropdownRow from "./components/dropdownRow";
import EmailRow from "./components/emailRow";
import Error from "./Error/Error";
import { Form } from "react-final-form";
import { HOST_API } from "./constant";
import HtmlElementRow from "./components/htmlElementRow";
import HyperlinkRow from "./components/hyperlinkRow";
import { IntlProvider } from "react-intl";
import NumberRow from "./components/numberRow";
import PhoneRow from "./components/phoneRow";
import RadioRow from "./components/radioRow";
import StaticTextRow from "./components/staticTextRow";
import TextRow from "./components/textRow";
import { get } from "lodash";

const App = ({ messages }, props) => {
  const [open, setOpen] = useState(false);

  const { language } = useContext(AppContext);
  const [elementIds, setElementIds] = useState([]);
  const [composedForms, setComposedForms] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [disableDelayCallButtons, setDisableDelayCallButtons] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setTimeMessage] = useState(false);
  const [onSuccess, setOnSuccess] = useState("");
  const [message, setMessage] = useState(false);
  const [wrongNumberMessage, setWrongNumberMessage] = useState(false);
  const { t } = props;

  const query1 = new URLSearchParams(window.location.search);
  const formId = query1.get("form");

  const query = new URLSearchParams(window.location.search);
  const dbid = query.get("dbid");
  const agent = query.get("agent");

  let db = useMemo(
    () => ({
      taskId: dbid,
    }),
    [dbid]
  );

  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  let emailRE = new RegExp(mailformat);
  let phoneRE = new RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  let decimalRE = new RegExp(/^\d+(,\d{1,2})?$/);
  let dateRE = new RegExp(/^\d{2}[./-]\d{2}[./-]\d{4}$/);

  const getData = useCallback( () => {
    setLoading(true);
    let param = {
      id: formId.toString(),
    };

    getComposedForm(param)
      .then(async (res) => {
        const payload = {
          ...db,
          ...(agent && { agent }),
        };
        let data = await getCallTask(payload);

        let value, dataKey;

        data &&
          res.sections.forEach((sec) => {
            sec.elements &&
              sec.elements.forEach((elemn) => {
                var dataDB = data[0];

                value = get(dataDB, elemn.inputPath);

                dataKey = elemn.elementId;

                if (value) {
                  elemn.valueDB = value;
                  setFormData((prevState) => ({
                    ...prevState,
                    [dataKey]: value,
                  }));
                }
              });
          });
        setComposedForms(res);
      })
      .catch((err) => {
        setError(permissionErrorCheck(err));
        setComposedForms([]);
      });
    setLoading(false);
  }, [agent, db, formId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getDropdownValues = (element) => {
    let options = [<option value={``}> Select Type</option>];
    let optionsArray = JSON.parse(element.values);

    if (!optionsArray.values.length) {
      element &&
        element.valueDB &&
        element.valueDB.forEach((value) => {
          options.push(<option value={value}>{value}</option>);
        });
    } else {
      let optionsArray = JSON.parse(element.values);
      optionsArray.values.forEach((option) => {
        options.push(<option value={option.id}>{option.text}</option>);
      });
    }
    return options;
  };

  const getCheckboxValues = (element) => {
    let checkboxes = [];
    let checkboxesArray = JSON.parse(element.values);

    checkboxesArray.values.forEach((checkbox) => {
      checkboxes.push(
        <>
          <input
            type="checkbox"
            defaultValue={checkbox.text}
            disabled={disableForm && true}
            id={checkbox.id}
            value={checkbox.id}
            name={element.name}
            onChange={(e) => {
              handleChange(e, "checkbox", element.elementId);
              const currentId = element.elementId;
              if (!elementIds?.includes(currentId)) {
                setElementIds((prevState) => [...prevState, currentId]);
              }
            }}
            readOnly={element.readOnly}
          />
          &nbsp;
          <label htmlFor={checkbox.id}>{checkbox.text}</label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      );
    });
    return checkboxes;
  };

  const getRadioButtonValues = (element) => {
    let radios = [];
    let radiosArray = JSON.parse(element.values);

    radiosArray.values.forEach((radio) => {
      radios.push(
        <>
          <input
            type="radio"
            disabled={disableForm && true}
            id={radio.id}
            value={radio.id}
            name={element.name}
            onChange={(e) => {
              handleChange(e, "radio", element.elementId);
              const currentId = element.elementId;
              // check if currentId is not present in elementId array
              if (!elementIds?.includes(currentId)) {
                setElementIds((prevState) => [...prevState, currentId]);
              }
            }}
            readOnly={element.readOnly}
          />
          &nbsp;
          <label htmlFor="radio">{radio.text}</label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      );
    });
    return radios;
  };

  const submitDelayCall = (value, endsInputValue) => {
    var payload = {
      external_id: dbid,
      next_time_call: value,
    };

    Axios.post(`${HOST_API}/api/v1/CallTask/DelayCall/`, payload)
      .then(() => {
        if (endsInputValue === true) {
          setDisableForm(true);
        } else {
          setDisableForm(false);
        }

        setDisableDelayCallButtons(true);
      })
      .catch(() => {
        if (endsInputValue === true) {
          setDisableForm(true);
        } else {
          setDisableForm(false);
        }
      });
  };

  const getRedialButtonValues = (element) => {
    try {
      let buttons = [];
      let buttonsArray = JSON.parse(element.values);
      let endsInputValue = JSON.parse(element.endsInput);

      buttonsArray.values.forEach((button) => {
        buttons.push(
          <>
            <input
              type="button"
              value={button.text}
              disabled={
                (disableForm && true) || (disableDelayCallButtons && true)
              }
              id={button.id}
              name={element.name}
              className="btn btn-primary mt-3"
              readOnly={element.readOnly}
              onClick={(e) => {
                submitDelayCall(button.id, endsInputValue);
              }}
            />{" "}
            &nbsp;
          </>
        );
      });

      return buttons;
    } catch (error) {
      return "err";
    }
  };

  const handleAddForm = async () => {
    var payload = {
      task_id: dbid,
    };

    switch (onSuccess) {
      case "interested":
        composedForms &&
          composedForms.sections.forEach((sec) => {
            sec?.elements?.forEach((elemn) => {
              let email = elemn.inputPath.split(".").slice(-1)[0];
              if (email && "email") {
                payload.status = "11";
              } else {
                payload.status = "10";
              }
            });
          });
        break;

      case "notInterested":
        composedForms &&
          composedForms.sections.forEach((sec) => {
            sec?.elements?.forEach((elemn) => {
              if (elemn.saveOnReject === true) {
                payload.status = "16";
              } else {
                payload.status = "16";
                delete payload[elemn.elementId];
                delete formData[elemn.elementId];
              }
            });
          });
        break;

      case "doNotCallMeEverAgain":
        payload.status = "19";
        break;

      default:
        break;
    }

    payload = { ...payload, ...formData };

    // replace elementId with element Names in formData from sections
    Object.keys(payload).forEach((key) => {
      composedForms.sections.forEach((sec) => {
        sec?.elements?.forEach((elemn) => {
          if (elemn.elementId === key) {
            payload[elemn.name] = payload[key];
            delete payload[key];
          }
        });
      });
    });
    
    Axios.post(`${HOST_API}/api/v1/CallTask/TaskFinishedV2/`, payload)
      .then(() => {
        setDisableForm(true);
        if (formData.block && formData.block === "time") {
          setTimeMessage({
            type: "success",
            text: renderFormatMsg("save_msg"),
          });
        } else {
          setMessage({
            type: "success",
            text: renderFormatMsg("save_msg"),
          });
        }
      })
      .catch(() => {
        setDisableForm(true);
        if (formData.block && formData.block === "time") {
          setTimeMessage({
            type: "danger",
            text: renderFormatMsg("error_msg"),
          });
        } else {
          setMessage({
            type: "success",
            text: renderFormatMsg("save_msg"),
          });
        }
      });
  };

  const handleChange = useCallback(
    (event, type = "", elementId = "") => {
      let _formData = { ...formData };
      if (type === "checkbox") {
        if (_formData[elementId]) {
          if (event.target.checked) {
            _formData[elementId].push(event.target.value);
          } else {
            let index = _formData[elementId].indexOf(event.target.value);
            _formData[elementId].splice(index, 1);
          }
        } else {
          _formData[elementId] = [event.target.value];
        }
        if (_formData[elementId].length === 0) {
          delete _formData[elementId];
          setElementIds(elementIds.filter((id) => id !== elementId));
        }
        setFormData(_formData);
      } else {
        const value = event.target.value;
        if (value) {
          setFormData({
            ...formData,
            [elementId]: value,
          });
        } else {
          const { [elementId]: deleted, ...rest } = formData;
          setFormData({
            ...rest,
          });
        }
      }
    },
    [elementIds, formData]
  );

  const submitWrongNumber = () => {
    var payload = {
      status: "17",
      task_id: dbid,
    };

    Axios.post(`${HOST_API}/api/v1/CallTask/TaskFinished/`, payload)
      .then(() => {
        setDisableForm(true);
        setWrongNumberMessage({
          type: "success",
          text: t("save_msg"),
        });
        window.open("", "_self").close();
      })
      .catch((err) => {
        setDisableForm(true);
        setWrongNumberMessage({
          type: "danger",
          text: t("error_msg"),
        });
        window.open("", "_self").close();
      });
  };

  const setErrorMsg = () => {
       let listEle = [];
       composedForms?.sections?.length &&
          composedForms?.sections?.forEach((sec) => {
            const elementClassName = getSectionClassName(sec);
            if(elementClassName !== "card d-none"){
              sec?.elements?.forEach((elemn) => {
                console.log("elemn", elemn)
                let elementId = elemn.elementId;
                let eleValue = elemn.name;
                let eleType = elemn.elementType;
                let eleValueDB = elemn.valueDB;
                if (
                  onSuccess === "interested" &&
                  elemn.mandatoryOnSuccess
                ) {
                  listEle.push({ elementId, eleValue, eleType, eleValueDB });
                } else if (
                  onSuccess === "notInterested" &&
                  elemn.mandatoryOnReject
                ) {
                  listEle.push({ elementId, eleValue, eleType, eleValueDB });
                }
          });
        }});

        console.log("listEle", listEle);
       
          let objValue = {};
          listEle &&
            listEle?.forEach((checkValue) => {
              if (!formData[checkValue.elementId]) {
                console.log("here", checkValue.eleValue)
                objValue[checkValue.eleValue] = "Required";
              }
              if (
                !formData[checkValue.elementId] &&
                (!checkValue.eleValueDB || checkValue.eleValueDB === "")
              ) {
                
                objValue[checkValue.eleValue] = "Required";
              } else if (checkValue.eleType === "TwoColumnEmailRow") {
                if (
                  emailRE.test(String(formData[checkValue.elementId]).toLowerCase())
                ) {
                  Axios.post(`${HOST_API}/api/v1/CallTask/email/`, {
                    email: formData[checkValue.elementId],
                  });
                } else {
                  objValue[checkValue.eleValue] = "Please enter valid email address";
                }
              } else if (
                !phoneRE.test(formData[checkValue.elementId]) &&
                checkValue.eleType === "TwoColumnPhoneRow"
              ) {
                objValue[checkValue.eleValue] = "Please enter valid phone number";
              } else if (
                !decimalRE.test(formData[checkValue.elementId]) &&
                checkValue.eleType === "TwoColumnNumberRow"
              ) {
                objValue[checkValue.eleValue] = "Please enter valid decimal number";
              } else if (
                !dateRE.test(formData[checkValue.elementId]) &&
                checkValue.eleType === "TwoColumnDateRow"
              ) {
                objValue[checkValue.eleValue] = "Please enter valid date";
              }
            });
            return objValue;
        }
        

  
  if (loading) return <>Loading...</>;
  const handleOldConditionElementClass = (section) => {
    if (formData[section.conditionElement]) {
      if (
        elementIds.includes(section.conditionElement) &&
        (Array.isArray(formData[section.conditionElement])
          ? formData[section.conditionElement].includes(section.conditionValue)
          : formData[section.conditionElement] === section.conditionValue)
      )
        return "d-block";
      return "d-none";
    }
    return section.conditionElement ? "d-none" : "d-block";
  };

  const handleConditionElementClass = (section) => {
    let elementClassName = [];
    const conditionType = section?.conditionLogic?.conditionType;
    section?.conditionLogic?.conditions?.forEach((condition) => {
      if (formData?.[condition.element]) {
        if (
          elementIds.includes(condition?.element) &&
          (Array.isArray(formData[condition.element])
            ? formData[condition.element].includes(condition.value)
            : formData[condition.element] === condition.value)
        ) {
          elementClassName.push("d-block");
          
        } else elementClassName.push("d-none");
      } else {
        if (section?.conditionLogic?.conditions?.length)
          elementClassName.push("d-none");
        else elementClassName.push("d-block");
      }
    });
    if (conditionType === "and")
      return elementClassName?.filter((className) => className === "d-none")
        .length
        ? "d-none"
        : "d-block";
    else if (conditionType === "or")
      return elementClassName?.filter((className) => className === "d-block")
        .length
        ? "d-block"
        : "d-none";
  };

  const handlePrevVersion = (section) => {
    if (section.conditionElement) {
      return handleOldConditionElementClass(section);
    }
    return handleConditionElementClass(section);
  };
  const getSectionClassName = (section) => {
    const cardClass = section.type !== "SaveButtonSection" ? "card" : "";
    const prevVersionClass = handlePrevVersion(section) || "";
    
    return `${cardClass} ${prevVersionClass}`.trim();
  };
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="main-content">
        <div className="d-flex justify-content-end">
          <Grid item>
            <Button
              size="sm"
              onClick={() => setOpen(!open)}
              aria-controls="description-text"
              aria-expanded={open}
              className="m-2"
            >
              {open ? "Hide" : "Show"} Description
            </Button>
          </Grid>
        </div>
        <div className="card">
          {error ? (
            <Error msg={error} />
          ) : (
            <Form onSubmit={handleAddForm} validate={setErrorMsg} id='flexForm'>
              {({ handleSubmit, values, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item className="mt-4 mb-4 ml-4 mr-4">
                      {composedForms?.sections?.length > 0 &&
                        composedForms.sections.map((section) => {
                          const elementClassName = getSectionClassName(section);
                          if(elementClassName === "card d-none") return null;
                          return (
                            <div
                              key={section.sectionId}
                              className={elementClassName}
                            >
                              <div className="cardHeading">
                                {section.type !== "SaveButtonSection" && (
                                  <Typography
                                    className="text-monospace"
                                    variant={"h6"}
                                  >
                                    {section?.title || section.name}
                                  </Typography>
                                )}
                              </div>
                              <div className="card-body pb-2">
                                {section.type === TWO_COLUMN_SECTION && (
                                  <Grid container alignItems={"flex-start"}>
                                    {section?.elements?.length > 0 &&
                                      section.elements.map((element) => (
                                        <Grid
                                          key={element.elementId}
                                          item
                                          xs={12}
                                          className="altrow mt-2 ml-4 mr-4"
                                        >
                                          {element.elementType ===
                                            STATIC_TEXT && (
                                              <StaticTextRow element={element} />
                                            )}
                                          {element.elementType ===
                                            TWO_COLUMN_ACTION_DROPDOWN && (
                                              <ActionDropdownRow
                                                element={element}
                                                values={values}
                                                elementIds={elementIds}
                                                setElementIds={setElementIds}
                                                handleChange={handleChange}
                                                disableForm={disableForm}
                                                getDropdownValues={getDropdownValues}
                                                nameError={errors[element.name]} />
                                            )}
                                          {element.elementType ===
                                            TWO_COLUMN_TEXT_ROW && (
                                              <TextRow
                                                element={element}
                                                handleChange={handleChange}
                                                disableForm={disableForm}
                                                nameError={errors[element.name]}
                                                mobileError={errors.mobile}
                                                open={open} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_CHECKBOX_ROW && (
                                              <CheckboxRow
                                                element={element}
                                                getCheckboxValues={getCheckboxValues}
                                                nameError={errors[element.name]}
                                                open={open} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_RADIO_BUTTON_ROW && (
                                              <RadioRow
                                                element={element}
                                                nameError={errors[element.name]}
                                                open={open}
                                                getRadioButtonValues={getRadioButtonValues} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_EMAIL_ROW && (
                                              <EmailRow
                                                text={element.text}
                                                fieldLength={element.fieldLength}
                                                name={element.name}
                                                valueDB={element.valueDB}
                                                readOnly={element.readOnly}
                                                emailError={errors?.email}
                                                handleChange={handleChange}
                                                elementId={element.elementId}
                                                disableForm={disableForm}
                                                description={element.description}
                                                nameError={errors?.[element.name]}
                                                fieldDescription={element?.fieldDescription}
                                                open={open} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_PHONE_ROW && (
                                              <PhoneRow
                                                element={element}
                                                disableForm={disableForm}
                                                handleChange={handleChange}
                                                mobileError={errors?.mobile}
                                                nameError={errors?.[element.name]}
                                                open={open} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_NUMBER_ROW && (
                                              <NumberRow
                                                element={element}
                                                handleChange={handleChange}
                                                open={open}
                                                disableForm={disableForm}
                                                numberError={errors?.number}
                                                nameError={errors?.[element.name]} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_DATE_ROW && (
                                              <DateRow
                                                element={element}
                                                handleChange={handleChange}
                                                disableForm={disableForm}
                                                dobError={errors.DOB}
                                                nameError={errors?.[element.name]}
                                                open={open} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_DROPDOWN_ROW && (
                                              <DropdownRow
                                                element={element}
                                                open={open}
                                                values={values}
                                                elementIds={elementIds}
                                                setElementIds={setElementIds}
                                                handleChange={handleChange}
                                                disableForm={disableForm}
                                                getDropdownValues={getDropdownValues}
                                                nameError={errors?.[element.name]} />
                                            )}

                                          {element.elementType ===
                                            TWO_COLUMN_HYPERLINK && (
                                              <HyperlinkRow element={element} open={open} />
                                            )}

                                          {element.elementType ===
                                            HTML_ELEMENT && (
                                              <HtmlElementRow element={element} />
                                            )}

                                          {element.elementType ===
                                            WRONG_NUMBER_BUTTON && (
                                              <Grid item xs={12} className="">
                                                <Grid
                                                  container
                                                  alignItems={"flex-end"}
                                                  direction={"column"}
                                                >
                                                  <Grid item>
                                                    <button
                                                      type="button"
                                                      onClick={(e) => {
                                                        if (!disableForm) {
                                                          submitWrongNumber();
                                                        } else {
                                                          e.preventDefault();
                                                        }
                                                      } }
                                                      style={{ padding: 8 }}
                                                      className={`btn btn-danger text-right ${disableForm && `disabled`}`}
                                                      disabled={disableForm && true}
                                                    >
                                                      {element.text}
                                                    </button>
                                                  </Grid>
                                                  <Grid item>
                                                    {wrongNumberMessage &&
                                                      wrongNumberMessage.text && (
                                                        <span
                                                          className="mt-3"
                                                          style={{
                                                            color: wrongNumberMessage.type ===
                                                              "success"
                                                              ? "#4caf50"
                                                              : "#f00",
                                                          }}
                                                        >
                                                          {wrongNumberMessage.text}
                                                        </span>
                                                      )}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            )}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}

                                {section.type === HTML_SECTION && (
                                  <Grid container alignItems={"flex-start"}>
                                    {section?.elements?.length > 0 &&
                                      section.elements.map((element) => (
                                        <Grid
                                          key={element.elementId}
                                          item
                                          xs={12}
                                          className="altrow ml-4 mr-4"
                                        >
                                          {element.elementType ===
                                            HTML_ELEMENT && (
                                              <Grid
                                                container
                                                justifyContent={"space-between"}
                                              >
                                                <Grid item>

                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: element.valueDB || "",
                                                    }}
                                                  ></div>
                                                </Grid>
                                              </Grid>
                                            )}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}

                                {section.type === REDIAL_SECTION && (
                                  <Grid container alignItems={"flex-start"}>
                                    {section?.elements?.length > 0 &&
                                      section.elements.map((element) => (
                                        <Grid
                                          key={element.elementId}
                                          item
                                          xs={12}
                                          className="altrow ml-3 mr-4"
                                        >
                                          {element.elementType ===
                                            REDIAL_BUTTON && (
                                              <Grid
                                                container
                                                justifyContent={"space-between"}
                                              >
                                                <Grid item>

                                                  <div readOnly={element.readOnly}>
                                                    {getRedialButtonValues(element)}
                                                  </div>
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={7}
                                                  style={{ textAlign: "left" }}
                                                ></Grid>
                                              </Grid>
                                            )}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}

                                {section.type === COMPLAINT_SECTION &&
                                  section?.elements?.length > 0 &&
                                  section.elements.map((element) => (
                                    <Grid
                                      key={element.elementId}
                                      container
                                      alignItems={"flex-start"}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className="mt-4 mb-4 ml-4 mr-4"
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ textAlign: "right" }}
                                        >
                                          <textarea
                                            name={element.name}
                                            className="form-control"
                                            onChange={(e) => handleChange(
                                              e,
                                              "",
                                              element.elementId
                                            )}
                                            readOnly={element.readOnly}
                                            disabled={disableForm && true}
                                          ></textarea>

                                          {errors[element.name] && (
                                            <span style={{ color: "red" }}>
                                              {errors[element.name]}
                                            </span>
                                          )}
                                        </Grid>
                                        <Grid item style={{ textAlign: "right" }}>
                                          <input
                                            disabled={disableForm && true}
                                            type="checkbox"
                                            id="chkbox"
                                            name="canWeCallInvestigateReasons"
                                            onChange={(e) => handleChange(
                                              e,
                                              "",
                                              element.elementId
                                            )}
                                            value={true} />{" "}
                                          &nbsp;
                                          <label htmlFor="chkbox">
                                            {renderFormatMsg(
                                              "can_we_call_to_investigate_reasons"
                                            )}
                                          </label>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}

                                {section.type === SAVE_BUTTON_SECTION && (
                                  <Grid
                                    container
                                    alignItems={"flex-start"}
                                    className="mb-2"
                                  >
                                    {section?.elements?.length > 0 &&
                                      section.elements.map((element) => (
                                        <Grid
                                          key={element.elementId}
                                          item
                                          xs={12}
                                          className="altrow mr-4 mt-3"
                                        >
                                          {element.elementType ===
                                            SAVE_BUTTON_LINE && (
                                              <Grid container spacing={1}>
                                                <Grid item>
                                                  <button
                                                    type="submit"
                                                    style={{ padding: 8 }}
                                                    className={`btn btn-secondary btn-success`}
                                                    disabled={disableForm && true}
                                                    onClick={() => setOnSuccess("interested")}
                                                  >
                                                    {renderFormatMsg(
                                                      "interested",
                                                      "Interested"
                                                    )}
                                                  </button>
                                                </Grid>

                                                <Grid item>
                                                  <button
                                                    type="submit"
                                                    style={{ padding: 8 }}
                                                    className={`btn btn-secondary btn-danger`}
                                                    disabled={disableForm && true}
                                                    onClick={() => setOnSuccess("notInterested")}
                                                  >
                                                    {renderFormatMsg(
                                                      "not_interested",
                                                      "Not interested"
                                                    )}
                                                  </button>
                                                </Grid>

                                                <Grid item>
                                                  <button
                                                    type="submit"
                                                    style={{ padding: 8 }}
                                                    className={`btn btn-secondary`}
                                                    onClick={() => setOnSuccess(
                                                      "doNotCallMeEverAgain"
                                                    )}
                                                    disabled={disableForm && true}
                                                  >
                                                    {renderFormatMsg(
                                                      "do_not_call_me_ever_again",
                                                      "Do not call me ever again"
                                                    )}
                                                  </button>
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={7}
                                                  style={{ textAlign: "right" }}
                                                ></Grid>
                                              </Grid>
                                            )}
                                          {message && message.text && (
                                            <span
                                              className="mt-3"
                                              style={{
                                                color: message.type === "success"
                                                  ? "#4caf50"
                                                  : "#f00",
                                              }}
                                            >
                                              {message.text}
                                            </span>
                                          )}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </Grid>
                  </form>
                );
              }}
            </Form>
          )}
        </div>
      </div>
    </IntlProvider>
  );
};

export default App;
