import { Box, Grid } from "@material-ui/core";

import FieldDescription from "./fieldDescription";
import React from "react";
import ToggleDescription from "./toggleDescription";
import parse from "html-react-parser";

const HyperlinkRow = ({ element, open }) => {
  const isOpen = open && element?.description;

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={isOpen ? 10 : 12}>
      <FieldDescription description={element?.fieldDescription} />

        <Box className="d-flex align-items-center">
          <a href={element.valueDB} target="_blank" rel="noopener noreferrer">
            {element.text}
          </a>
          {isOpen ? "" : element?.description && <div className="pl-1 mb-2">...</div>}
        </Box>
      </Grid>
      {isOpen && (
        <Grid item xs={2}>
          <ToggleDescription
            open={open}
            description={parse(element?.description || "")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default HyperlinkRow;
